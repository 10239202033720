<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        :max-width="e1 == 3 ? '1200px' : '700px'"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close(0)"
      >
        <v-card :loading="loading">
          <v-card-title>Saisissez les attributs</v-card-title>
          <v-card-text>
            <v-container>
              <v-row
                v-for="attribut in attributs_list"
                :key="attribut.id"
                dense
                no-gutters
              >
                <v-col cols="12" sm="3" md="3">
                  <v-subheader>
                    {{ attribut.name }}
                  </v-subheader>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="8"
                  v-if="attribut.display == 'Fichier'"
                >
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="attribut.value"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    @change="produit_change"
                    @input="attribut_id = attribut.id"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-else-if="att_values(attribut.id).length == 0"
                >
                  <v-text-field
                    v-model="attribut.value"
                    :rules="[
                      (v) =>
                        !!v ||
                        attribut.is_null == 1 ||
                        attribut.name + ' obligatoire',
                    ]"
                    dense
                    @input="update_attribut(attribut.id, attribut.value)"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-else-if="att_values(attribut.id).length > 0"
                >
                  <v-autocomplete
                    v-model="attribut.value"
                    :items="att_values(attribut.id)"
                    item-value="id"
                    item-text="name"
                    :rules="[
                      (v) =>
                        !!v ||
                        attribut.is_null == 1 ||
                        attribut.name + ' obligatoire',
                    ]"
                    dense
                    small-chips
                    @input="update_attribut(attribut.id, attribut.value)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row
                v-if="
                  Template.tomanufacture == 1 &&
                  (prd_type == '03' || prd_type == '04')
                "
              >
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="boms"
                    v-model="variant.process_id"
                    item-text="process_name"
                    item-value="process_id"
                    label="Procede de Fabrication"
                    :rules="[
                      (v) => !!v || 'Procede de Fabrication obligatoire',
                    ]"
                    outlined
                    readonly
                    @change="process_change"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-row
                    v-for="(bom, index) in boms_values.filter(
                      (elm) =>
                        elm.attributes.filter((elm) => elm.technique == 0)
                          .length > 0
                    )"
                    :key="index"
                    dense
                  >
                    <v-col cols="12" sm="2" md="2">
                      {{ bom.caption }}
                    </v-col>

                    <v-col
                      cols="12"
                      :sd="3"
                      :md="3"
                      v-for="attribute in bom.attributes.filter(
                        (elm) => elm.technique == 0
                      )"
                      :key="attribute.id"
                    >
                      <v-text-field
                        v-model="attribute.value"
                        :label="
                          attribute.name +
                          (attribute.suffixe
                            ? '(' + attribute.suffixe + ')'
                            : '')
                        "
                        :rules="[
                          (v) =>
                            !!v ||
                            bom.opt == 1 ||
                            attribute.name + ' obligatoire',
                        ]"
                        dense
                        @focus="$event.target.select()"
                        v-if="att_values(attribute.attribute_id).length == 0"
                      ></v-text-field>
                      <v-autocomplete
                        v-model="attribute.value_id"
                        :items="att_values(attribute.attribute_id)"
                        item-value="id"
                        item-text="name"
                        :label="
                          attribute.name +
                          (attribute.suffixe
                            ? '(' + attribute.suffixe + ')'
                            : '')
                        "
                        :rules="[
                          (v) =>
                            !!v ||
                            bom.opt == 1 ||
                            attribute.name + ' obligatoire',
                        ]"
                        dense
                        small-chips
                        v-else
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="save"
            :disabled="!variant_ok || btn_disable"
          >
            Créer Variante
          </v-btn>
          <v-btn color="primary" @click="close(0)" class="mx-1">
            Annuler
          </v-btn>
        </v-toolbar>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
import PRODUCTS_CURSOR from "../graphql/Mouvment/PRODUCTS_CURSOR.gql";
import INSERT_PRODUCT from "../graphql/Product/INSERT_PRODUCT.gql";
//import TEMPLATE from "../graphql/Template/TEMPLATE.gql";
import ALLPRODUCTS from "../graphql/Product/ALLPRODUCTS.gql";
export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "productassistform",
  props: {
    item: Object,
    showForm: Boolean,
    list_modeles: Array,
    attributs: Array,
    values_list: Array,
    route: Object,
    prd_type: String,
    boms: Array,
    templates: Array,
    product: Object,
    units: Array,
  },
  data: () => ({
    valid: null,
    btn_disable: false,
    loading: false,
    e1: 1,
    type: "0",
    Item_list_selected: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    //variant_list: [],
    Template: {},
    attributs_sel: [],
    attribut: "",
    value: "",
    attribut_id: null,
    cs: 100,
    selected_modele: null,
    variant: {},
    boms_values: [],
    standard: false,
    process: {},
  }),

  computed: {
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    variableadd() {
      let w = {
        Kind: this.prd_type == "03" ? 3 : this.prd_type == "02" ? 2 : 34,
        statut_id: 2,
      };
      return w;
    },
    attributs_list() {
      let list = [];

      if (this.Template)
        if (this.Template.tabtempattributs)
          this.Template.tabtempattributs.forEach((element) => {
            let i = this.attributs.findIndex((elm) => elm.id == element);
            if (i >= 0)
              if (
                this.prd_type == "03" ? this.attributs[i].technique == 0 : true
              )
                list.push(this.attributs[i]);
          });
      return list;
    },
    variant_ok() {
      let ok = true;
      for (let index = 0; index < this.attributs_list.length; index++) {
        const attribut = this.attributs_list[index];
        if (attribut.is_null == 0) {
          if (
            this.attributs_sel.findIndex(
              (elm) => elm.attribute_id == attribut.id
            ) == -1
          ) {
            ok = false;
            break;
          }
        }
      }
      if (ok) {
        if (this.boms_list) {
          let list = this.boms_list.filter((elm) =>
            elm.attributes
              ? elm.attributes.filter((elm) => elm.technique == 0).length > 0
              : false
          );
          for (let index = 0; index < list.length; index++) {
            const bom = list[index];
            if (bom.opt == 0) {
              let l = bom.attributes.filter((elm) => elm.technique == 0);
              for (let index = 0; index < l.length; index++) {
                const attribut = l[index];

                if (attribut.value == null && attribut.value_id == null) {
                  ok = false;
                  break;
                }
              }
            }
            if (!ok) break;
          }
        }
      }
      return ok;
    },
    boms_list() {
      let l = [];
      if (this.variant) {
        l = this.boms.filter(
          (elm) =>
            elm.process_id == this.variant.process_id && elm.template_id != null
        );
      }

      return l;
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.standard =
      this.$store.state.auth.includes("02072") || this.$store.state.isadmin;

    if (this.boms) {
      this.step2();
      this.get_boms();
    }
  },
  methods: {
    get_boms() {
      //boms
      this.boms_values = [];
      let boms = [];
      if (this.product.boms) {
        boms = JSON.parse(this.product.boms);
      }
      boms.forEach((element) => {
        let i = this.boms_list.findIndex(
          (elm) => elm.id == element.fk_process_gamme_bom
        );
        if (i >= 0) {
          let j2 = this.templates.findIndex(
            (elm) => elm.id == element.fk_template
          );
          if (j2 >= 0) {
            element.support = this.templates[j2].support;
            let j3 = this.units.findIndex(
              (elm) => elm.id == this.templates[j2].fk_unit_ratio
            );
            if (j3 >= 0) element.ratio_unit_name = this.units[j3].name;
          }
          element.template_id = element.fk_template;
          element.caption = this.boms_list[i].caption;
          element.gamme_parent_name = this.boms_list[i].gamme_parent_name;

          element.attributes.forEach((attribut) => {
            let j = this.attributs.findIndex(
              (elm) => elm.id == attribut.attribute_id
            );
            if (j >= 0) {
              attribut.readonly = this.attributs[j].technique == 0;
              attribut.technique = this.attributs[j].technique;
              attribut.name = this.attributs[j].name;
              attribut.suffixe = this.attributs[j].suffixe;
              attribut.control = this.attributs[j].control;
            }
          });

          this.boms_values.push(element);
        }
      });
    },
    process_change() {
      let i = this.boms.findIndex(
        (elm) => elm.process_id == this.variant.process_id
      );
      if (i >= 0) this.process = this.boms[i];
    },

    template_change(bom) {
      let i = this.templates.findIndex((elm) => elm.id == bom.template_id);
      bom.attributes = [];
      if (i >= 0) {
        let ta = this.templates[i].tabtempattributs
          ? this.templates[i].tabtempattributs
          : [];
        let l = this.attributs.filter(
          (elm) =>
            ta.includes(elm.id) && (elm.hyritage == 1 || elm.control == 1)
        );
        l.forEach((element) => {
          bom.attributes.push({
            id: element.id,
            name: element.name,
            suffixe: element.suffixe,
            control: element.control,
            hyritage: element.hyritage,
            technique: element.technique,
            display: element.control == 0,
            is_null: element.is_null,
          });
        });
      }
    },

    produit_change(item, l) {
      let i = l.findIndex((elm) => elm.id == item);
      let v;
      if (i >= 0) v = l[i].code + "-" + l[i].label;
      this.update_attribut(this.attribut_id, v);
    },
    att_values(id) {
      let list = this.values_list.filter((elm) => elm.attribute_id == id);
      return list;
    },
    list_PAV(list, mp) {
      let list_pav = [];
      list
        .filter((elm) => elm.mp == mp)
        .forEach((attribut) => {
          let values = this.values_list.filter(
            (elm) => elm.attribute_id == attribut.attribute_id
          );
          if (values.length > 0)
            list_pav.push({
              attribute_id: attribut.attribute_id,
              attribute_value_id: attribut.value_id,
            });
          else
            list_pav.push({
              attribute_id: attribut.attribute_id,
              value: attribut.value_id,
            });
        });

      return list_pav;
    },

    delchips(item) {
      this.attributs_sel.remove(item);
    },
    close(e, item) {
      if (e == 1) this.$emit("close", item);
      else if (e == 0) {
        this.$emit("close");
        if (this.route.route_name) {
          if (this.route.produit_id) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                produit_id: this.route.produit_id,
                of_id: this.route.of_id,
                filtre: this.route.label,
              },
            });
          }
          if (this.route.cmd) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                cmd: this.route.cmd,
                item_id: this.route.item_id,
              },
            });
          } else if (this.route.pfm) {
            this.$router.push({
              name: this.route.route_name,
              params: {
                pfm: this.route.pfm,
                item_id: this.route.item_id,
              },
            });
          }
        }
      }
    },
    back2() {
      this.e1 = 2;
      this.Template = {};
    },
    step1() {
      if (this.type == 1) {
        let item = this.item;
        item.id = -1;
        this.$emit("close", item);
      } else this.e1 = 2;
    },
    modele_select(item) {
      this.selected_modele = item;
    },
    async step2() {
      this.e1 = 3;
      let i = this.list_modeles.findIndex(
        (x) => x.id == this.product.fk_template
      );
      if (i >= 0) this.Template = this.list_modeles[i];
      //this.variant_list = this.Template.variants;
      this.variant = Object.assign({}, this.Template);
      if (this.product) this.variant.process_id = this.product.process_id;
      this.process_change();
      this.attributs_list.forEach((element) => {
        let j = this.product.attributs.findIndex(
          (elm) => elm.attribute_id == element.id
        );
        let v;
        if (j >= 0) {
          if (this.product.attributs[j].value)
            v = this.product.attributs[j].value;
          if (this.product.attributs[j].attribute_value_id)
            v = this.product.attributs[j].attribute_value_id;
        }
        element.value = v;
        this.update_attribut(element.id, v);
      });

      // this.boms_values.forEach((bom) => {
      //   bom.attributes = [];

      //   let i = this.templates.findIndex((elm2) => elm2.id == bom.template_id);

      //   if (i >= 0) {
      //     let ta = this.templates[i].tabtempattributs
      //       ? this.templates[i].tabtempattributs
      //       : [];
      //     let l = this.attributs.filter(
      //       (elm) =>
      //         ta.includes(elm.id) &&
      //         (elm.hyritage == 1 || elm.control == 1) &&
      //         (this.prd_type == "03" ? elm.technique == 0 : true)
      //     );

      //     l.forEach((element) => {
      //       bom.attributes.push({
      //         id: element.id,
      //         name: element.name,
      //         suffixe: element.suffixe,
      //         control: element.control,
      //         hyritage: element.hyritage,
      //         display: this.Template.tabtempattributs.includes(element.id),
      //         is_null: element.is_null,
      //         values: [],
      //       });
      //     });
      //   }
      // });
      //}
    },
    update_attribut(attribut, value) {
      if (value != null && value != "") {
        let i;
        let val_name;
        let val_color;
        let val_ref;
        let att_name;
        let suffixe;
        let display;
        let incl_sell, incl_buy, incl_manuf, is_null;
        i = this.attributs.findIndex((elm) => elm.id == attribut);
        if (i >= 0) {
          att_name = this.attributs[i].caption ? this.attributs[i].caption : "";
          suffixe = this.attributs[i].suffixe ? this.attributs[i].suffixe : "";
          display = this.attributs[i].display;
          incl_sell = this.attributs[i].incl_sell;
          incl_buy = this.attributs[i].incl_buy;
          incl_manuf = this.attributs[i].incl_manuf;
          is_null = this.attributs[i].is_null;
        }
        let values = this.values_list.filter(
          (elm) => elm.attribute_id == attribut
        );
        if (values.length > 0 && display != "Fichier") {
          i = values.findIndex((elm) => elm.id == value);
          if (i >= 0) {
            val_name = values[i].name;
            val_color = values[i].html_color;
            val_ref = values[i].ref;
          }
        } else val_name = value;

        i = this.attributs_sel.findIndex((elm) => elm.attribute_id == attribut);
        if (i >= 0) {
          this.attributs_sel[i].value_id = value;
          this.attributs_sel[i].attribut_name = att_name;
          this.attributs_sel[i].value_name = val_name;
          this.attributs_sel[i].val_color = val_color;
          this.attributs_sel[i].val_ref = val_ref;
        } else
          this.attributs_sel.push({
            technique: 0,
            attribute_id: attribut,
            value_id: value,
            attribut_name: att_name,
            value_name: val_name,
            val_color: val_color,
            val_ref: val_ref,
            incl_sell: incl_sell,
            incl_buy: incl_buy,
            incl_manuf: incl_manuf,
            suffixe: suffixe,
            mp: false,
            is_null: is_null,
          });

        const sortArray = (arr1, arr2) => {
          arr2.sort((a, b) => {
            const aKey = Object.values(a)[0];
            const bKey = Object.values(b)[0];
            return (
              arr1.map((elm) => elm.id).indexOf(aKey) -
              arr1.map((elm) => elm.id).indexOf(bKey)
            );
          });
        };
        sortArray(this.attributs_list, this.attributs_sel);
        //this.value = "";
      } else {
        let i = this.attributs_sel.findIndex(
          (elm) => elm.attribute_id == attribut
        );
        if (i >= 0) {
          this.attributs_sel.splice(i, 1);
        }
      }
    },
    add_attribut(attribut, value, mp, fk_template, fk_process_gamme_bom) {
      let i;
      let val_name;
      let val_color;
      let val_ref;
      let att_name;
      let suffixe;
      let display;
      let incl_sell, incl_buy, incl_manuf, control, hyritage, is_null;
      i = this.attributs.findIndex((elm) => elm.id == attribut);

      if (i >= 0) {
        att_name = this.attributs[i].caption ? this.attributs[i].caption : "";
        suffixe = this.attributs[i].suffixe ? this.attributs[i].suffixe : "";
        display = this.attributs[i].display;
        incl_sell = this.attributs[i].incl_sell;
        incl_buy = this.attributs[i].incl_buy;
        incl_manuf = this.attributs[i].incl_manuf;
        control = this.attributs[i].control;
        hyritage = this.attributs[i].hyritage;
        is_null = this.attributs[i].is_null;
      }
      let values = this.values_list.filter(
        (elm) => elm.attribute_id == attribut
      );
      if (values.length > 0 && display != "Fichier") {
        i = values.findIndex((elm) => elm.id == value);
        if (i >= 0) {
          val_name = values[i].name;
          val_color = values[i].html_color;
          val_ref = values[i].ref;
        }
      } else val_name = value;

      return {
        technique: 1,
        fk_process_gamme_bom: fk_process_gamme_bom,
        fk_template: fk_template,
        attribute_id: attribut,
        value_id: value,
        attribut_name: att_name,
        value_name: val_name,
        val_color: val_color,
        val_ref: val_ref,
        incl_sell: incl_sell,
        incl_buy: incl_buy,
        incl_manuf: incl_manuf,
        suffixe: suffixe,
        mp: mp,
        control: control,
        hyritage: hyritage,
        is_null: is_null,
      };
    },
    async maj(query, v) {
      let r;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.btn_disable = true;
        let att_list = [];
        let boms_values = [];
        let v_null = "";
        this.boms_values.forEach((element) => {
          if (
            element.attributes.findIndex(
              (elm) => elm.value_id != null || elm.value != null
            ) >= 0 ||
            element.opt == 0
          ) {
            let list = [];
            element.attributes.forEach((att) => {
              let k = this.attributs.findIndex(
                (elm) => elm.id == att.attribute_id
              );

              if (k >= 0) {
                if (this.attributs[k].technique == 1)
                  list.push({
                    attribute_id: att.attribute_id,
                  });
                else
                  list.push({
                    attribute_id: att.attribute_id,
                    value: att.value,
                    value_id: att.value_id,
                  });
              }
            });
            if (
              element.multiple == 0 ||
              (element.multiple == 1 && !element.new)
            )
              boms_values.push({
                fk_process_gamme_bom: element.fk_process_gamme_bom,
                fk_template: element.template_id,
                attributes: list,
                multiple: element.multiple,
                new: element.new,
              });
            //}
            if (element.attributes)
              element.attributes.forEach((elm) => {
                if (this.att_values(elm.id).length == 0) {
                  if (
                    elm.is_null == 1 &&
                    elm.value_id == null &&
                    this.attributs_list.findIndex(
                      (e) => e.id == elm.attribute_id
                    ) == -1
                  )
                    v_null = v_null + element.caption + " : " + elm.name + ",";
                  att_list.push(
                    this.add_attribut(
                      elm.attribute_id,
                      elm.value_id,
                      true,
                      element.fk_template,
                      element.fk_process_gamme_bom
                    )
                  );
                } else
                  att_list.push(
                    this.add_attribut(
                      elm.attribute_id,
                      elm.value_id,
                      true,
                      element.fk_template,
                      element.fk_process_gamme_bom
                    )
                  );
              });
          }
        });
        this.attributs_list.forEach((element) => {
          if (element.is_null == 1) {
            let i = this.attributs_sel.findIndex(
              (elm) => elm.attribute_id == element.id
            );
            if (i == -1) v_null = v_null + element.name + ",";
          }
        });
        this.attributs_sel.forEach((element) => {
          let elm = Object.assign({}, element);
          att_list.push(elm);
        });
        if (this.variant.tomanufacture == 1 && this.prd_type == "03") {
          let attr_tech = this.attributs.filter(
            (elm) =>
              this.Template.tabtempattributs.includes(elm.id) &&
              elm.technique == 1
          );
          attr_tech.forEach((element) => {
            att_list.push(
              this.add_attribut(element.id, null, false, this.Template.id, null)
            );
          });
        }
        let new_variant = [];
        att_list
          .filter((elm) => elm.value_id != null)
          .forEach((element) => {
            new_variant.push(element.value_id);
          });
        let ok = true;
        if (v_null) {
          this.btn_disable = false;
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Attributs Manquant !! Etês-vous sûr?",
              { color: "orange darken-3" },
              v_null
            )
          ) {
            ok = true;
            this.btn_disable = true;
          } else ok = false;
        }
        if (ok) {
          let result = false;
          let label =
            this.process.processus_included == 1
              ? this.process.process_caption
                ? this.process.process_caption + " "
                : ""
              : "";
          label =
            label +
            (this.Template.included == 1 ? this.Template.label.trim() : "");
          //   let label_com;

          // let ref = "";
          for (let index = 0; index < att_list.length; index++) {
            const element = att_list[index];

            if (
              (this.variant.tosell && element.incl_sell == 1) ||
              (this.variant.tobuy && element.incl_buy == 1) ||
              (this.variant.tomanufacture && element.incl_manuf == 1)
            )
              label =
                label.trim() +
                (element.value_name
                  ? element.attribut_name
                    ? " " + element.attribut_name.trim()
                    : ""
                  : "") +
                (element.value_name
                  ? " " +
                    element.value_name.trim() +
                    (element.suffixe ? element.suffixe.trim() : "")
                  : "");
          }
          //verif double variant
          let r22 = await this.requette(ALLPRODUCTS, {
            where: {
              AND: [{ column: "LABEL", value: label }],
            },
          });
          if (r22) {
            result = r22.allproducts.length > 0;
          }

          if (result) {
            this.snackbar_color = "error";
            this.snackbar_text = "Variante existe déja!!";
            this.snackbar = true;
            this.btn_disable = false;
          } else {
            let atelier_id = null;
            if (this.variant.tomanufacture == "1") {
              atelier_id = 1;
            }

            this.variant.attributs = new_variant;
            this.variant.fk_template = this.Template.id;
            this.variant.label = label;

            this.variant.cmddetailcount = 0;
            this.variant.statut_id = "1";
            // this.variant.ref = ref;
            ///////
            let cat = [];
            if (this.variant.categories) {
              this.variant.categories.split(",").forEach((categorie) => {
                cat.push(categorie);
              });
            }

            let pfm_det_id;

            if (this.route.pfm) {
              pfm_det_id = this.route.item_id;
            }
            let list_pav = this.list_PAV(att_list, false);
            let v = {
              cat: cat,
              create_uid: this.$store.state.me.id,
              atelier_id: atelier_id,
              pfm_det_id: pfm_det_id,
              product: [
                {
                  ref: this.variant.ref,
                  label: this.variant.label,
                  label_com: this.variant.label_com,
                  description: this.variant.description,
                  note: this.variant.note,
                  customcode: this.variant.customcode,
                  tosell: this.variant.tosell ? 1 : 0,
                  tobuy: this.variant.tobuy ? 1 : 0,
                  tomanufacture: this.variant.tomanufacture ? 1 : 0,
                  is_moule: this.variant.is_moule ? 1 : 0,
                  bylist: this.variant.bylist ? 1 : 0,
                  statut_id: this.variant.statut_id,
                  fk_product_type: this.variant.fk_product_type,
                  tva_tx: this.variant.tva_tx,
                  boms:
                    boms_values.length > 0 ? JSON.stringify(boms_values) : null,
                  attributs_json:
                    list_pav.length > 0 ? JSON.stringify(list_pav) : null,
                  colisattributs: this.variant.colisattributs,
                  maxvalue:
                    this.variant.maxvalue > 0
                      ? parseFloat(this.variant.maxvalue)
                      : null,
                  minvalue:
                    this.variant.minvalue > 0
                      ? parseFloat(this.variant.minvalue)
                      : null,
                  barcode: this.variant.barcode,
                  fk_barcode_type: this.variant.fk_barcode_type,
                  fk_unit: this.variant.fk_unit,
                  fk_unit_colis: this.variant.fk_unit_colis,
                  fk_unit_buy: this.variant.fk_unit_buy,
                  url: this.variant.url,
                  code_cmpt: this.variant.code_cmpt,
                  service: this.variant.service,
                  client_id: this.route.client_id,
                  fk_template: this.variant.fk_template,
                  decimal: this.variant.decimal,
                  reg_date: this.variant.reg_date,
                  durration: this.variant.durration,
                  depot_id: this.variant.depot_id,
                  prixvente: this.variant.prixvente,
                  process_id: this.variant.process_id,
                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              ],
            };

            let r = await this.maj(INSERT_PRODUCT, v);

            ///////
            if (r) {
              this.variant.id = r.InsertProduct.id;
              this.variant.code = r.InsertProduct.code;
              //this.variant_list.unshift(this.variant);

              this.snackbar_color = "success";
              this.snackbar_text = "Variante ajoutée avec succes";
              this.snackbar = true;
              this.variant.docs = [];
              this.variant.fournisseurs = [];
              this.variant.tiers_list = [];
              this.variant.qte_prix = [];
              this.variant.tier_type_prix = [];
              this.boms_list.forEach((element) => {
                element.fk_template = null;
              });
              this.$refs.form.resetValidation();
              if (!this.variant.gammes) this.variant.gammes = [];
              if (!this.variant.categories) this.variant.categories = [];
              else this.variant.categories = this.variant.categories.split(",");
              if (this.route.route_name) {
                if (this.route.produit_id) {
                  this.$router.push({
                    name: this.route.route_name,
                    params: {
                      produit_id: this.route.produit_id,
                      of_id: this.route.of_id,
                      filtre: this.route.label,
                    },
                  });
                }
                if (this.route.cmd) {
                  this.$router.push({
                    name: this.route.route_name,
                    params: {
                      cmd: this.route.cmd,
                      item_id: this.route.item_id,
                      produit_id: this.variant.id,
                    },
                  });
                }
                if (this.route.pfm) {
                  this.$router.push({
                    name: this.route.route_name,
                    params: {
                      pfm: this.route.pfm,
                      item_id: this.route.item_id,
                      produit_id: this.variant.id,
                    },
                  });
                }
              } else {
                this.$emit("add", this.variant);
                this.$store.dispatch("Changed", true);
                this.close(1, this.variant);
              }
            }
          }
        }
      }
    },
  },
};
</script>
