var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":_vm.e1 == 3 ? '1200px' : '700px',"persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close(0)}},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v("Saisissez les attributs")]),_c('v-card-text',[_c('v-container',[_vm._l((_vm.attributs_list),function(attribut){return _c('v-row',{key:attribut.id,attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-subheader',[_vm._v(" "+_vm._s(attribut.name)+" ")])],1),(attribut.display == 'Fichier')?_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('cursorselect',{key:_vm.cs,attrs:{"Qsearch":_vm.products_cursor,"Qresp":'products_cursor',"value":attribut.value,"text_fields":['code', 'label'],"variableadd":_vm.variableadd,"label":'Produit'},on:{"change":_vm.produit_change,"input":function($event){_vm.attribut_id = attribut.id}}})],1):(_vm.att_values(attribut.id).length == 0)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"rules":[
                    function (v) { return !!v ||
                      attribut.is_null == 1 ||
                      attribut.name + ' obligatoire'; } ],"dense":""},on:{"input":function($event){return _vm.update_attribut(attribut.id, attribut.value)},"focus":function($event){return $event.target.select()}},model:{value:(attribut.value),callback:function ($$v) {_vm.$set(attribut, "value", $$v)},expression:"attribut.value"}})],1):(_vm.att_values(attribut.id).length > 0)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.att_values(attribut.id),"item-value":"id","item-text":"name","rules":[
                    function (v) { return !!v ||
                      attribut.is_null == 1 ||
                      attribut.name + ' obligatoire'; } ],"dense":"","small-chips":""},on:{"input":function($event){return _vm.update_attribut(attribut.id, attribut.value)}},model:{value:(attribut.value),callback:function ($$v) {_vm.$set(attribut, "value", $$v)},expression:"attribut.value"}})],1):_vm._e()],1)}),_c('v-divider'),(
                _vm.Template.tomanufacture == 1 &&
                (_vm.prd_type == '03' || _vm.prd_type == '04')
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.boms,"item-text":"process_name","item-value":"process_id","label":"Procede de Fabrication","rules":[
                    function (v) { return !!v || 'Procede de Fabrication obligatoire'; } ],"outlined":"","readonly":"","dense":""},on:{"change":_vm.process_change},model:{value:(_vm.variant.process_id),callback:function ($$v) {_vm.$set(_vm.variant, "process_id", $$v)},expression:"variant.process_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},_vm._l((_vm.boms_values.filter(
                    function (elm) { return elm.attributes.filter(function (elm) { return elm.technique == 0; })
                        .length > 0; }
                  )),function(bom,index){return _c('v-row',{key:index,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_vm._v(" "+_vm._s(bom.caption)+" ")]),_vm._l((bom.attributes.filter(
                      function (elm) { return elm.technique == 0; }
                    )),function(attribute){return _c('v-col',{key:attribute.id,attrs:{"cols":"12","sd":3,"md":3}},[(_vm.att_values(attribute.attribute_id).length == 0)?_c('v-text-field',{attrs:{"label":attribute.name +
                        (attribute.suffixe
                          ? '(' + attribute.suffixe + ')'
                          : ''),"rules":[
                        function (v) { return !!v ||
                          bom.opt == 1 ||
                          attribute.name + ' obligatoire'; } ],"dense":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(attribute.value),callback:function ($$v) {_vm.$set(attribute, "value", $$v)},expression:"attribute.value"}}):_c('v-autocomplete',{attrs:{"items":_vm.att_values(attribute.attribute_id),"item-value":"id","item-text":"name","label":attribute.name +
                        (attribute.suffixe
                          ? '(' + attribute.suffixe + ')'
                          : ''),"rules":[
                        function (v) { return !!v ||
                          bom.opt == 1 ||
                          attribute.name + ' obligatoire'; } ],"dense":"","small-chips":""},model:{value:(attribute.value_id),callback:function ($$v) {_vm.$set(attribute, "value_id", $$v)},expression:"attribute.value_id"}})],1)})],2)}),1)],1):_vm._e()],2)],1)],1),_c('v-toolbar',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.variant_ok || _vm.btn_disable},on:{"click":_vm.save}},[_vm._v(" Créer Variante ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.close(0)}}},[_vm._v(" Annuler ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),[_c('confirmdialog',{ref:"confirm"})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }